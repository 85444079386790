import React, { Component } from 'react';
import { createGlobalStyle } from 'styled-components';
import WebFont from 'webfontloader';

import { StyledApp, Grid, Row, CellResults, CellTerminal, backgroundColor } from './Styles';
import Source from './Source';
import Compiled from './Compiled';
import Results from './Results';
import Terminal from './Terminal';

WebFont.load({
  google: {
    families: ['Roboto', 'Open Sans']
  }
});

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto;
    font-size: 18px;
    background-color: ${backgroundColor};
    * {
      box-sizing: border-box;
    }

    h1,h2,h3,h4,h5,h6 {
      font: Roboto;
    }
  }
`;


class App extends Component {
  render() {
    return (
      <StyledApp className="App">
        <GlobalStyle />
        <header className="App-header">ML2SQL</header>
        <Grid>
          <Row flex="1.5">
            <Source />
            <Compiled>
              <p>Result Code</p>
            </Compiled>
          </Row>
          <Row>
            <CellTerminal>
              <Terminal />
            </CellTerminal>
          </Row>
        </Grid>
      </StyledApp>
    );
  }
}

export default App;
