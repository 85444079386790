import { observable } from 'mobx';

export const exampleSelected = observable.box('Example 1');
export const sourceValue = observable.box(`A = [
    [1.1,0.98,87.3,3],
    [0.1,3.15,42.05,3.3],
    [100.5,26.8,10.1,225.1],
    [1097.5,23000,10.1,24850.1]]
X = A[: , 0:2]
y = A[: , 3]
bias[1,len(X,0)] : 1
X = (bias::X.T).T
Xt = X.T
w = (Xt*X)^(-1) * Xt * y
print '%' , w`);

export const examples = observable({
    'Example 1': `A = [
        [1.1,0.98,87.3,3],
        [0.1,3.15,42.05,3.3],
        [100.5,26.8,10.1,225.1],
        [1097.5,23000,10.1,24850.1]]
    X = A[: , 0:2]
    y = A[: , 3]
    bias[1,len(X,0)] : 1
    X = (bias::X.T).T
    Xt = X.T
    w = (Xt*X)^(-1) * Xt * y
    print '%' , w`,
    'Algebra': `import time
import distributions
start_load = time()
#include "ml2sql/examples/include/loadTaxiData.ml"
end_load = time()
start_create = time()
create tensor X from taxiData(trip_seconds, trip_miles, pickup_community_area, dropoff_community_area, fare, tips)
end_create = time()
start_trans = time()
Xt = X.T
end_trans = time()
start_mul = time()
A = Xt * X
end_mul = time()
start_add = time()
A = X + X
end_add = time()
start_sub = time()
A = X - X
end_sub = time()
print 'Time load: %' , (end_load -  start_load)
print 'Time create: %' , (end_create -  start_create)
print 'Time trans: %' , (end_trans -  start_trans)
print 'Time mul: %' , (end_mul -  start_mul)
print 'Time add: %' , (end_add -  start_add)
print 'Time sub: %' , (end_sub -  start_sub)
print 'Time total: %' , ( (end_load -  start_load) + (end_create -  start_create) + (end_trans -  start_trans) +  (end_mul -  start_mul) + (end_add -  start_add) + (end_sub -  start_sub) )    `,
    'Example 2': `import functions
import time
#include "ml2sql/examples/include/loadTaxiData.ml"
create tensor DATA from taxiData(trip_seconds,trip_miles,fare ,tips)
X = DATA[: , 0:2]
y = DATA[: , 3]
startTime = time()
bias[1,len(X,0)] : 1
X = (bias::X.T).T
Xt = X.T
w = (Xt*X)^(-1) * Xt * y
err = X * w - y
avgLoss = sum(err.T * err)/len(X)
endTime = time()
print 'Loss: %' , avgLoss
print 'Time: %' , endTime - startTime`,
    'Regression closed': `import time
import regression
import functions
#include "ml2sql/examples/include/loadTaxiData.ml"
#include "ml2sql/examples/include/loadTestTaxiData.ml"
create tensor DATA from taxiData(trip_seconds, trip_miles, pickup_community_area, dropoff_community_area, fare, tips)
create tensor TEST_DATA from taxiDataTest(trip_seconds, trip_miles, pickup_community_area, dropoff_community_area, fare, tips)
X = DATA[: , 0:4]
y = DATA[: , 5]
X_test = TEST_DATA[: , 0:4]
y_test = TEST_DATA[: , 5:5]
start_bias = time()
X = addBiasTerm(X)
X_test = addBiasTerm(X_test)
end_bias = time()
start_reg = time()
w = regression(X , y)
end_reg = time()
start_pred = time()
err = predict(X_test , w) - y_test
avgLoss = sum(err.T * err)/len(X_test)
end_pred = time()
print 'AvgLoss: %' , avgLoss
print 'Time bias: %' , (end_bias -  start_bias)
print 'Time reg: %' , (end_reg -  start_reg)
print 'Time pred: %' , (end_pred -  start_pred)
print 'Time total: %' , ( (end_bias -  start_bias) +  (end_reg -  start_reg) + (end_pred -  start_pred) )`,
    'Cross Validation': `import time
import regression
import functions
#include "ml2sql/examples/include/loadTaxiData.ml"
create tensor DATA from taxiData(fare, tips)
y = DATA[: , 1]
X = DATA[: , 0]
float0 function pol(X , i){
    return (X[0,0]^i)
}
float0 function lossFunction(X , y , w){
    res = X * w - y
    loss = sum(res.T * res)
    return loss/len(X)
}
start_cross = time()
crossvalidate{
    minfun: w = regression_reg(X,y,reg)
    lossfun: lossFunction(X,y,w)
    kernel: pol:X:4
    data: X,y
    folds: 10
    n: len(X)
    test{
        reg=[0.0;0.000005]:0.0000005
    }
}
end_cross = time()
print 'Cross time %' , (end_cross - start_cross)
print  '%' , w`,
    'Lin. Reg. GD': `import time
import regression
import functions
import distributions
#include "ml2sql/examples/include/loadTaxiData.ml"
#include "ml2sql/examples/include/loadTestTaxiData.ml"
create tensor DATA from taxiData(trip_seconds, trip_miles, pickup_community_area, dropoff_community_area, fare, tips) 
create tensor TEST_DATA from taxiDataTest(trip_seconds, trip_miles, pickup_community_area, dropoff_community_area, fare , tips) 
X = DATA[: , 0:4]
y = DATA[: , 5]
X_test = TEST_DATA[: , 0:4]
y_test = TEST_DATA[: , 5:5]
start_bias = time()
X = addBiasTerm(X)
X_test = addBiasTerm(X_test)
end_bias = time()
// Set start value to zero
w[6,1] : 0
start_reg = time()
gradientdescent {
    function:'pow((X*w-y),2)'
    data: X , y
    optimize: w
    learningrate: 0.0000001
    threshold: 0.0000000000000000000000001
    maxsteps: 10000
    batchsize: 1000
}
end_reg = time()
start_pred = time()
err = predict(X_test , w) - y_test
avgLoss = sum(err.T * err)/len(X_test)
end_pred = time()
print '%' , avgLoss
print 'Time bias: %' , (end_bias -  start_bias)
print 'Time reg: %' , (end_reg -  start_reg)
print 'Time pred: %' , (end_pred -  start_pred)
print 'Time total: %' , ( (end_bias -  start_bias) +  (end_reg -  start_reg) + (end_pred -  start_pred) )`,
    'Sample and pow': `import time
import distributions
start_sample = time()
A [1000,1000] ~ Uniform(0,10)
end_sample = time()
start_pow = time()
A = A^(-2)
end_pow = time()
print 'Time sample: %' , (end_sample -  start_sample)
print 'Time pow: %' , (end_pow -  start_pow)
print 'Time total: %' , ((end_sample -  start_sample) + (end_pow -  start_pow))`,
    'Test': `import distributions
A [10,10] ~ Uniform(0,10)
print '%' , A`,
})

export const targetLanguage = observable.box('python');
export const compiledValue = observable.box(`import numpy as np
def main():
    A = np.array([ np.array([ 1.1,0.98,87.3,3]),np.array([0.1,3.15,42.05,3.3]),np.array([ 100.5,26.8,10.1,225.1]),np.array([1097.5,23000,10.1,24850.1])])
    X = A[ :,0:2 +1]
    y = A[ :,3:3 +1]
    bias = np.full( ( 1,np.size(X ,0 )), 1)
    X = (np.append(bias,X.T, axis=0)).T
    Xt = X.T
    w = np.dot( np.dot( np.linalg.matrix_power((np.dot( Xt,X)), (-1)),Xt),y)
    print( '{}'.format(  w))
main()`)

export const resultValue = observable.box(
    `$ python < main.py\r\n\r\n
[[ 0.12238861]\r\n[ 1.9759119 ]\r\n[ 0.98614977]\r\n[-0.00300479]]\r\n`,
);
