import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Flex } from './Styles';
import SourceBase from './SourceBase';
import Select from './Select';
import * as Api from '../execapi';

import { resultValue, targetLanguage, compiledValue } from '../state';

const Heading = styled.div`
font-family: 'Roboto';
min-width: 168px;
`

async function doExecute() {
    try {
        const response = await Api.execute(targetLanguage.get(), compiledValue.get());
        resultValue.set(response.result.trim());
    } catch(err) {
        console.error("Execution error: ", err);
    }
}

const Compiled = observer((props) => {
  const heading = (
    <Flex align="center" height="100%">
      <Heading>Target Language:</Heading>
      <Select
        options={[
          { value: 'hyper', name: 'HyPerScript' },
          { value: 'postgres', name: 'pl/pgSQL' },
          { value: 'python', name: 'Python' }
        ]}
        selected={ targetLanguage }
      />
    </Flex>
  );
  const action = 'Execute';
  const language = 'python';

  return <SourceBase
    heading={heading}
        action={action}
        onAction={doExecute}
        language={language}
        value={compiledValue} />;
});

export default Compiled;
