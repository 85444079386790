import * as React from 'react';
import { autorun } from 'mobx';
import { Terminal } from 'xterm';
import * as attach from 'xterm/lib/addons/attach/attach';
import * as fit from 'xterm/lib/addons/fit/fit';
import 'xterm/dist/xterm.css';
import styled from 'styled-components';

import { resultValue } from '../state';

const StyledTerminal = styled.div`
  padding: 8px 8px 0px 8px;
  background-color: black;
  height: 100%;
`;

class TerminalComponent extends React.Component {
  componentWillMount() {
    Terminal.applyAddon(attach);
    Terminal.applyAddon(fit);
    this.terminal = new Terminal();
  }
  componentDidMount() {
    this.terminal.open(this.refs.terminal);
    this.terminal.fit();
    autorun(() => {
        this.terminal.reset();
        this.terminal.write(resultValue.get())
    });
  }
  render() {
    return <StyledTerminal ref="terminal">
      </StyledTerminal>
  }
}

export default TerminalComponent;
