import * as React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import classNames from 'classnames';
import { mainColor, backgroundColor } from './Styles';

class Select extends React.Component {
  create_select() {
    var div_cont_select = this.refs.select_div;
    var select_ = '';
    div_cont_select.setAttribute('data-selec-open', 'false');
    var ul_cont = div_cont_select.querySelectorAll('.cont_list_select_mate > ul');
    select_ = div_cont_select.querySelector('select');
    var select_optiones = select_.options;
    const option_click_cb = (i) => () => this.select_option(i);
    for (var i = 0; i < select_optiones.length; i++) {
      this.li[i] = document.createElement('li');
      if (select_optiones[i].selected === true || select_.value === select_optiones[i].innerHTML) {
        this.li[i].className = 'active';
        div_cont_select.querySelector('.selecionado_opcion ').innerHTML = select_optiones[i].innerHTML;
      }
      // funcion click al selecionar
      this.li[i].addEventListener('click', option_click_cb(i));

      this.li[i].innerHTML = select_optiones[i].innerHTML;
      ul_cont[0].appendChild(this.li[i]);
    }
  }
  open_select() {
    const main_div = this.refs.select_div;
    var hg = 0;
    var slect_open = main_div.getAttribute('data-selec-open');
    for (var i = 0; i < this.li.length; i++) {
      hg += this.li[i].offsetHeight;
    }
    if (slect_open === 'false') {
      main_div.setAttribute('data-selec-open', 'true');
      main_div.querySelectorAll('.cont_list_select_mate > ul')[0].style.height = hg + 'px';
      main_div.querySelectorAll('.icon_select_mate')[0].style.transform = 'rotate(180deg)';
    } else {
      main_div.setAttribute('data-selec-open', 'false');
      main_div.querySelectorAll('.icon_select_mate')[0].style.transform = 'rotate(0deg)';
      main_div.querySelectorAll('.cont_list_select_mate > ul')[0].style.height = '0px';
    }
  }
  salir_select() {
    const main_div = this.refs.select_div;
    main_div.querySelectorAll('.cont_list_select_mate > ul')[0].style.height = '0px';
    main_div.querySelector('.icon_select_mate').style.transform = 'rotate(0deg)';
    main_div.setAttribute('data-selec-open', 'false');
  }
  select_option(indx) {
    const main_div = this.refs.select_div;
    var select_ = main_div.querySelector('select');

    var select_optiones = main_div.querySelectorAll('select option');
    main_div.querySelector('.selecionado_opcion').innerHTML = this.li[indx].innerHTML;
    for (var i = 0; i < this.li.length; i++) {
      if (this.li[i].className === 'active') {
        this.li[i].className = '';
      }
      this.li[indx].className = 'active';
    }
    select_optiones[indx].selected = true;
    select_.selectedIndex = indx;
    this.salir_select();
    this.props.selected.set(this.props.options[indx].value);
  }
  componentDidMount() {
    this.li = [];
    this.create_select();
  }
  render() {
    return (
      <div className={classNames([ 'select_mate', this.props.className ])} ref="select_div">
        <select name="" value={ this.props.selected || this.props.options[0].value } onClick={() => false}>
          {this.props.options.map((option, i) => (
            <option value={option.value || option.name} key={i}>
              {option.name}
            </option>
          ))}
        </select>
        <p className="selecionado_opcion" onClick={this.open_select.bind(this)} />
        <span onClick={this.open_select.bind(this)} className="icon_select_mate">
          <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
            <path d="M0-.75h24v24H0z" fill="none" />
          </svg>
        </span>
        <div className="cont_list_select_mate">
          <ul className="cont_select_int"> </ul>
        </div>
      </div>
    );
  }
}

const StyledSelect = styled(Select)`
.icon_select_mate {
  position: absolute;
  top: 16px;
  right: 2%;
  font-size: 16px;
    height: 22px;
  transition: all 275ms;
}

font-family: "Open Sans";
position: relative;
float: left;
min-width: 200px;
width: 200px;
min-height: 54px;
background-color: #fff;
box-shadow: 1px 2px 10px -2px rgba(0,0,0,0.3);
border-radius: 3px;
transition: all 375ms ease-in-out;
/* Oculto el elemento select */
select {
position: absolute;
overflow: hidden;
height: 0px;
opacity: 0;
  z-index: -1;
}


.cont_list_select_mate {
  position: relative;
  float: left;
  width: 100%;
}

.cont_select_int {
 position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
  overflow: hidden;
  height: 0px;
  width: 100%;
  background-color: #fff;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2);
  transition: all 375ms ease-in-out;

	li {
	position: relative;
    float: left;
    width: 100%;
    border-bottom:1px solid #E0E0E0;
    background-color: white;
    list-style-type: none;
    padding: 10px 2%;
    margin: 0px;
    transition: all 0.3s ease-in-out;
    display: block;
    cursor:pointer;

		&:last-child {
      border-radius: 3px;
      border-bottom: 1px solid transparent;
    }
    &:hover {
      border-bottom: 1px solid ${mainColor}
    }
    }

   .active {
     background-color ${backgroundColor}
   }

}

/* etiqueta <p> con la opcion selecionada  */
.selecionado_opcion {
    padding: 15px 15px;
    width: auto;
    display: block;
    margin: 0px;
    cursor: pointer;
}

`;

export default StyledSelect;
