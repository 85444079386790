import * as React from 'react';
import styled from 'styled-components';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import { Flex } from './Styles';
import SourceBase from './SourceBase';
import Select from './Select';
import * as Api from '../api';
import { exampleSelected, sourceValue, targetLanguage, compiledValue, examples } from '../state';

const Heading = styled.div`
font-family: Robotico;
min-width: 96px;
`

async function doTranslate() {
    try {
        const result = await Api.translate(targetLanguage.get(), sourceValue.get());
        compiledValue.set(result.result.trim());
    } catch (err) {
        console.error("API error: ", err);
    }
}

autorun(() => {
    sourceValue.set(examples[exampleSelected]);
});

const Source = observer((props) => {
  const heading = (
    <Flex align="center" height="100%">
      <Heading>MLearn:</Heading>
      <Select
        options={Object.keys(examples).map(
            name => ({value: name, name: name }))}
        selected={ exampleSelected }
      />
    </Flex>
  );
  const action = 'Translate';
  const language = 'python';

  return <SourceBase
  heading={heading}
  action={action}
  onAction={ doTranslate }
  language={language}
  value={ sourceValue }
  />;
});

export default Source;
