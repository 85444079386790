import * as React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MonacoEditor from 'react-monaco-editor';

const monacoOptions = {
  overviewRulerBorder: false,
  automaticLayout: false,
  fontSize: 18,
  renderLineHighlight: "none",
  wordWrap: "on",
};

const CustomizedMonacoEditor = observer((props) => {
  return (
    <MonacoEditor
      width="100%"
      height="100%"
      language={props.language}
      options={monacoOptions}
      onChange={props.onChange}
      value={props.value.get()}
    />
  );
});

CustomizedMonacoEditor.propTypes = {
  language: PropTypes.string,
  value: PropTypes.string
};

export default CustomizedMonacoEditor;
