import * as React from 'react';
import styled from 'styled-components';
import Table from 'rc-table';
import 'rc-table/assets/index.css';


const StyledTable = styled.table`
  font-size: 18px;
  font-family: "Open Sans";
`

const StyledHeaderCell = styled.th`
  padding-left: 32px;
`

const StyledBodyCell = styled.td`
  padding-left: 32px;
`

const Results = (props) => {
  const columns = [
    {
      title: 'A',
      dataIndex: 'A',
      key: 'A',
      width: 100
    },
    {
      title: 'B',
      dataIndex: 'B',
      key: 'B',
      width: 100
    },
    {
      title: 'C',
      dataIndex: 'C',
      key: 'C',
      width: 200
    }
  ];

  const data = [
    { A: 1, B: 2, C:3, key: 1},
    { A: 1, B: 2, C:3, key: 2},
    { A: 1, B: 2, C:3, key: 3},
    { A: 1, B: 2, C:3, key: 4},
    { A: 1, B: 2, C:3, key: 5},
    { A: 1, B: 2, C:3, key: 6},
    { A: 1, B: 2, C:3, key: 7},
    { A: 1, B: 2, C:3, key: 8},
  ];

  const components = {
    table: StyledTable,
    header: {
      cell: StyledHeaderCell,
    },
    body: {
      cell: StyledBodyCell,
    },
  };

  return <Table columns={columns} data={data} indentSize={ 30 } components={components}/>;
};

export default Results;
