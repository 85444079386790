import * as React from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Flex, Box, borderStyle, mainColor, accentColor } from './Styles';
import styled from 'styled-components';

import MonacoEditor from './Monaco';

const Styled = styled.div`
 border-left: 6px solid white;
 border-right: 6px solid white;
 margin-bottom: 6px;
 width: 50%;
`;


const HeaderFlex = styled(Flex)`
padding: 16px;
padding-bottom: 0;
border-bottom: 1px solid ${mainColor}
padding-left: 84px;
padding-right: 84px;
padding-top: auto;
padding-bottom: 0;
align-content: bottom;
`

const Button = styled.button`
font-family: Roboto
border-bottom-left-radius: 0;
border-bottom-right-radius: 0;
box-shadow: 1px 2px 10px -2px rgba(0,0,0,0.3);
padding: 18px;
font-size: 16px;
text-transform: uppercase;

border: 1px solid ${accentColor};
background-color: transparent;
border-bottom: 1px solid white;
margin-bottom: -1px;
transition: all 0.3s ease-in-out;

}
&:hover {
  color: white;
  background-color: ${mainColor};
  border-bottom: 1px solid ${mainColor};
}
`

const SourceBase = observer((props) => {
  return (
    <Styled>
      <Flex direction="column" height="100%" justify="start">
        <Box flex="0 0 30px">
          <HeaderFlex direction="row">
            <Box>
              { props.heading }
            </Box>
            <Box>
              <Flex justify="flex-end">
                <Button onClick={ props.onAction }>{ props.action }</Button>
              </Flex>
            </Box>
          </HeaderFlex>
        </Box>
        <Box flex="1">
          <div style={{height: "100%"}}>
          <MonacoEditor
            language={ props.language }
            value={ props.value }
            onChange={ newValue => {
                props.value.set(newValue);
             } } />
          </div>
        </Box>
      </Flex>
    </Styled>
  );
});

SourceBase.propTypes = {
  heading: PropTypes.element.isRequired,
  action: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default SourceBase;
