import config from './config';

const backendURL = `http://${config.BACKEND_HOST}:${config.EXECUTION_PORT}`

function postRest(body) {
    return fetch(backendURL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
    }).then(response => response.json());
}

export function execute(lang, code) {
    return postRest({
        lang,
        code
    })
}
